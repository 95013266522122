<template>
  <div>
    <div class="fees-company-container">
      <b-alert variant="success" show class="d-flex justify-content-center align-items-center flex-column iq-border-radius-10">
        <h2 class="text-cyan font-weight-bold">{{ $t('insurance.paymentCompleted') }}</h2>
        <i class="fas fa-check-circle success_icon text-cyan my-2"></i>
        <p class="text-gray font-size-16 font-weight-bold mb-0">{{ $t('insurance.thanks') }}</p>
        <p class="text-gray font-weight-bold">
       {{$t('insurance.transferCamel')}}
          <router-link class="text-warning text-decoration-underline" :to="{name: 'transportation'}">{{ $t('insurance.clickHere') }}</router-link>
        </p>
      </b-alert>
      <div>
        <h3 class="fees-company-main-title text-gray">{{ $t('insurance.insureCompanyInfo') }}</h3>
        <b-row>
          <b-col lg="12">
            <div class="fees-company-item">
              <div class="d-flex justify-content-between align-items-end flex-md-row flex-column">
                <div class="d-flex gap_3">
                  <div class="fees-company-item-img">
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.insuranceCompany') }}</h5>
                      <h5 class="fees-company-item-response">{{info.selectedCompany.name}}</h5>
                    </div>
                    <img :src="info.selectedCompany.image" alt="" class="img-fluid" />
                  </div>
                  <div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.policyNumber') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">35623562365256</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.idHolder') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">{{ info.national_id }}</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.coverage') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">All risk-Institute Cargo Clause A</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">War & SRCCC (0.005% on SI)</h5>
                      <h5 class="fees-company-item-response font-size-16">Included</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.discountQuantity') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">0.10% of the Shipment Value. Subject to minimum</h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{$t("insurance.amountInsured")}}</h5>
                      <h5 class="fees-company-item-response font-size-16">{{info.totalFeesPackages}} {{ $t('insurance.rs') }}</h5>
                    </div>
                  </div>
                </div>
                <div class="w-25">
                  <div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.transportationCoverage') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">{{ info.selectedCompany.transportaion_coverage }} {{ $t('insurance.rs') }} </h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.valueTax') }} {{ info.selectedCompany.options.tax }}%</h5>
                      <h5 class="fees-company-item-response font-size-16">{{ (info.selectedCompany.options.tax / 100) * info.selectedCompany.transportaion_coverage }}  {{ $t('insurance.rs') }} </h5>
                    </div>
                    <div class="mb-4">
                      <h5 class="fees-company-item-title">{{ $t('insurance.CertificationFees') }}</h5>
                      <h5 class="fees-company-item-response font-size-16">{{info.selectedCompany.options.certificate_fees}} {{ $t('insurance.rs') }} </h5>
                    </div>
                    <hr/>
                    <div class="mb-4">
                      <h5 class="fees-company-item-response font-size-16">{{ $t('insurance.camelTotalValue') }}</h5>
                      <h3 class="font-weight-bold text-warning">  {{((info.selectedCompany.options.tax  / 100) * info.selectedCompany.transportaion_coverage) + info.selectedCompany.transportaion_coverage + info.selectedCompany.options.certificate_fees }} {{ $t('insurance.rs') }} </h3>
                    </div>
                    <div class="d-flex justify-content-start gap_2">
                      <b-button variant="primary" class="px-4 py-2 iq-border-radius-5 text-center"  @click="downloadPolicy(info.insurance_id)">
                        <span class="text-white">
                        {{ $t('insurance.insurancePolicy') }}
                        </span>
                      </b-button>
                      <b-button variant="outline-warning" class="px-4 py-2 iq-border-radius-5 text-center" @click="downloadWasl(info.insurance_id)">
                        <span class="text-warning">
                          {{ $t('insurance.receipt') }}
                        </span>
                      </b-button>
                    </div>
                    <router-link :to="{name: 'insurance-profile'}" class="mt-3 mb-0 font-size-16 text-gray d-flex align-items-center" tag="p">
                      {{ $t('insurance.gotToProfile') }} <i class="las la-angle-left"></i></router-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { VUE_APP_INSURANCE_LINK } from '@/config/constant'

export default {
  data () {
    return {
      insuranceCompanyInfos:
        {
          company: 'one',
          logo: require('../../../../../assets/images/ibbil/elraghi.png'),
          insuredAmount: '5,000 ريال سعودى',
          certificationFees: '20 ريال سعودى',
          transportationCoverage: '300 ريال سعودى',
          tax: '45 ريال سعودى',
          total: '365 ريال سعودى'
        }
    }
  },
  props: ['info'],
  components: {
  },
  methods: {
    downloadPolicy (id) {
      window.open(`${VUE_APP_INSURANCE_LINK}insurances/policy/${id}`, '_blank')
    },
    downloadWasl (id) {
      window.open(`${VUE_APP_INSURANCE_LINK}insurances/wasl/insurance/${id}`, '_blank')
    },
    submitFinal () {
      console.log('final')
    }
  }
}
</script>

<style  lang="scss">
.fees-company-container {
  margin-bottom: 52px;
  .fees-company-main-title {
    font-size: 20px !important;
    margin-bottom: 32px !important;
    font-weight: bold !important;
  }
  .fees-company-item {
    box-shadow: 0px 3px 20px #0000000D;
    padding: 45px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .3s;
    &.selected {
      border-color:#6EBF97;
    }
    .fees-company-item-body {
      padding-bottom: 27px;
      margin-bottom: 16px;
      border-bottom: 1px solid #DEDEDE;
    }
    .fees-company-item-info-box {
      margin-bottom: 22px;
      &.last {
        padding-bottom: 22px;
        border-bottom: 1px solid #DEDEDE;
      }
    }
    .fees-company-item-title {
      color: #B1B1B1;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .fees-company-item-response {
      color: #9B9B9B;
      font-size: 20px;
      font-weight: bold;
    }
    .fees-company-item-img, .fees-company-item-info-total {
      .fees-company-item-title {
        font-size: 20px;
      }
      .fees-company-item-response {
        font-size: 20px;
      }
    }
    .fees-company-item-info-total {
      .fees-company-item-response {
        color: #D39D45;
      }
    }
    .fees-company-item-img {
      img {
        max-width: 150px;
      }
    }
    .fees-company-item-note {
      color: #646464;
      font-size: 16px;
      max-width: 290px;
    }
    .privacy-btn {
      font-size: 16px;
      color: #646464;
      padding-bottom: 3px;
      border-bottom: 1px solid #646464;
      font-weight: bold;
      margin-inline-end: 18px;
    }
    .choose {
      height: 50px;
      border: 1px solid #2B584280;
      border-radius: 10px;
      color: #2B5842;
      font-size: 20px;
      width: 111px;
      background-color: transparent;
    }
  }
}
.bg-card-info {
  background: #2B58420D !important;
}
.flex-1 {
  flex: 1;
}
.bg-card-gray {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background: #2B58420D !important;
  .card-body {
    padding-bottom: 0 !important;
  }
}
.border-header-bottom {
  border-bottom: 1px solid rgba(148, 147, 147, 0.61);
}
.bg-primary-light {
  background: #2B5842BF !important;
}
.success_icon{
  font-size: 60px;
}
.text-decoration-underline{
  text-decoration: underline !important;
}
</style>
