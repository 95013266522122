<template>
<div>
  <ValidationObserver v-slot="{ handleSubmit }" ref="allIbbilSerialNumbersForm">
    <form  class="insuraceForm" @submit.prevent="handleSubmit(submitUSerInfo)">
      <b-row>
        <b-col md="6">
          <input-form v-model="userInfo.national_id"
                      :name='`national id`'
                      :label="$t('auth.IdNumber')"
                      type="text"
                      :validate=" { required: true, numeric:true, digits:10,  regex: /^[1-2][0-9]*$/}"
                      :placeholder="$t('auth.IdNumber')"
          ></input-form>
        </b-col>
        <b-col md="6">
          <input-form v-model="userInfo.full_name"  :name='`name`' :label="$t('auth.fullName')" type="text"
                      :validate=" { required: true,  regex: /[^0-9.]+/g}"
                      :placeholder="$t('auth.fullName')"></input-form>
        </b-col>
        <b-col md="6">
          <country-code
                :validate="'required|numeric|min:9|max:11'"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
                :placeholder="$t('auth.phoneNumber')"
                v-model="userInfo.phone"
                id="phone-code-primary_phone_number"
                @onSelect="onSelect"
              />
          <!-- <div class="d-flex align-items-center mb-3">
            <input-form
                class=" flex-1 flex-grow-1 phone-input"
                v-model="userInfo.phone"
                :validate="'required|numeric|min:9|max:11'"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
                :placeholder="$t('auth.phoneNumber')"

            />
            <div class="d-flex align-items-center">
              <vue-country-code
                  v-model="userInfo.country_code"
                  :onlyCountries="['sa', 'eg']"
                  :dropdownOptions="{ disabledDialCode: true }"
                  defaultCountry="sa"
                  class="phone_number_code"
              />
            </div>
          </div> -->
        </b-col>
<!--        <b-col md="6">-->
<!--          <div class="d-flex">-->
<!--            <input-form v-model="userInfo.phone"  :name='`phone Number`' label="رقم الهاتف" type="text" validate="required|numeric" placeholder=""></input-form>-->
<!--            <vue-country-code-->
<!--                v-model="code"-->
<!--                @onSelect="onSelect"-->
<!--                :onlyCountries="['sa', 'eg']"-->
<!--                :dropdownOptions="{ disabledDialCode: true }"-->
<!--                :enabledCountryCode= true-->
<!--                defaultCountry="sa"-->
<!--                class=""-->
<!--            />-->
<!--          </div>-->
<!--        </b-col>-->
        <b-col md="6">
          <input-form v-model="userInfo.email"  :name='`email`' :label="$t('auth.email')" :validate="{ required: true, regex: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ }"  type="text" :placeholder="$t('auth.email')"></input-form>
        </b-col>
        <b-col md="12">
          <input-form v-model="userInfo.address"  :name='`address`' :label="$t('auth.address')" type="text" validate="required" :placeholder="$t('auth.address')"></input-form>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mb-4">
        <b-button variant="primary" type="submit" class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold"> <span class="mx-2"> {{$t('insurance.continue')}}</span> <i class="las la-angle-left"></i> </b-button>
      </div>
    </form>
  </ValidationObserver>
</div>
</template>
<script>
export default {
  props: ['info'],
  data () {
    return {
      teste: JSON.parse(localStorage.getItem('userInfo')),
      userInfo: {
        country_code: '',
        national_id: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.national_id : '',
        phone: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.phone : '',
        email: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.email : '',
        address: '',
        full_name: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user.name : ''
      }
    }
  },
  mounted () {
  },
  methods: {
    onSelect (data) {
      this.country_code = data
    },
    submitUSerInfo () {
      localStorage.setItem('insuranceStep', 'packageInfo')
      this.$emit('changeForm', this.userInfo)
    }
  },
  created () {
    if (localStorage.getItem('insuranceInfo')) {
      this.userInfo = {
        national_id: JSON.parse(localStorage.getItem('insuranceInfo')).national_id,
        phone: JSON.parse(localStorage.getItem('insuranceInfo')).phone,
        email: JSON.parse(localStorage.getItem('insuranceInfo')).email,
        address: JSON.parse(localStorage.getItem('insuranceInfo')).address,
        full_name: JSON.parse(localStorage.getItem('insuranceInfo')).full_name
      }
    }
  }
}
</script>
<style>
.phone_number_code {
  margin-top: 15px;
  background-color: #fff !important;
  border-radius: 0 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.phone-input input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
