import { insurance } from '@/axios'

export default {
  getInsuranceCompanies ({ distance, totalFeesPackages }) {
    return insurance().get(`insuranceServiceProvider?limit=100&distance=${distance}&totalPrice=${totalFeesPackages}`)
  },
  checkSerial (serial) {
    return insurance().get(`camel/findbychipnumber/${serial}`)
  },
  createInsurance (payload) {
    return insurance().post('insurances', payload)
  },
  downloadPolicy (id) {
    return insurance().get(`insurances/policy/${id}`)
  },
  downloadWasl (id) {
    return insurance().get(`insurances/wasl/insurance/${id}`)
  }
}
