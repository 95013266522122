<template>
  <div class="fees-btns-container">
    <div class="mb-3">
      <b-button v-b-toggle.collapse-client-info @click="disabled = !disabled" :class="['overflow-hidden d-flex align-items-center justify-content-between position-relative w-100 fees-btn mb-1', disabled ? 'openSelected':'closeSelected']">
        <div :class="['opened position-absolute', disabled ? 'openSelected' : 'closeSelected']"></div>
        <span :class="['fees-text m-0 z-1', disabled ? 'text-white' : '']">{{ $t('insurance.customerData') }}</span>
        <i :class="['las la-angle-down icon z-1', disabled ? 'text-white' : '']"></i>
      </b-button>
      <b-collapse id="collapse-client-info" class="mb-4 fees-content-container">
        <b-row class="p-0 m-0 bg-white">
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('auth.IdNumber') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.national_id  }}</h5>
              </li>
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('insurance.insuranceHolder') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.full_name  }}</h5>
              </li>
            </ul>
          </b-col>
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('auth.phoneNumber') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.phone  }}</h5>
              </li>
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('auth.address') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.address  }}</h5>
              </li>
            </ul>
          </b-col>
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('auth.email') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.email  }}</h5>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
    <div class="mb-0">
      <b-button v-b-toggle.collapse-fees-info @click="disabledCompany = !disabledCompany" :class="['overflow-hidden d-flex align-items-center justify-content-between position-relative w-100 fees-btn mb-1', disabledCompany ? 'openSelected':'closeSelected']">
        <div :class="['opened position-absolute', disabledCompany ? 'openSelected' : 'closeSelected']"></div>
        <span :class="['fees-text m-0 z-1', disabledCompany ? 'text-white' : '']">{{ $t('insurance.shipmentInformation') }}</span>
        <i :class="['las la-angle-down icon z-1', disabledCompany ? 'text-white' : '']"></i>
      </b-button>
      <b-collapse id="collapse-fees-info" class="fees-content-container">
        <b-row class="p-0 m-0 bg-white">
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('auth.IdNumber') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.national_id  }}</h5>
              </li>
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{$t('insurance.insuranceHolder')}}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.full_name  }}</h5>
              </li>
            </ul>
          </b-col>
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('transportation.startingPoint') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.startText  }}</h5>
              </li>
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('transportation.destination') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.endText  }}</h5>
              </li>
            </ul>
          </b-col>
          <b-col md="4" class="p-0 m-0">
            <ul class="list-unstyled m-0 p-0 fees-content-items bg-t">
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('transportation.transportationDate') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.date  }}</h5>
              </li>
              <li class="fees-content-item d-flex align-items-start flex-column">
                <h5 class="fees-content-item-title title-info">{{ $t('transportation.tripDistance') }}</h5>
                <h5 class="fees-content-item-response m-0 title-info-gray">{{  info.distance / 1000  }} KM</h5>
              </li>
            </ul>
          </b-col>
          <b-col md="12" class="p-0 m-0">
            <div class="px-4 mb-2">
              <h5 class="fees-content-item-title title-info">{{ $t('insurance.camelTotalValue') }}</h5>
              <h5 class="fees-content-item-response m-0 text-warning font-weight-bold font-size-18">{{  info.totalFeesPackages  }}
                {{ $t('insurance.rs') }}</h5>
            </div>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      disabled: false,
      disabledCompany: false
    }
  },
  name: 'userInfoAndInsuranceCompany'
}
</script>

<style lang="scss">
.z-1{
  z-index: 1;
}
.opened {
  width: 0;
  right: 0;
  top: 0;
  height: 100%;
  transition: all 0.5s linear;
}
.openSelected {
  background: var(--iq-primary);
  width: 100%;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.closeSelected {
  width: 0;
}
.las.la-angle-down {
  transition: color 0.5s linear;
}
.fees-btns-container {
  margin-bottom: 40px;
.fees-btn {
//height: 100px;
  background-color: #fff !important;
  border: 1px solid #2B584280 !important;
  border-radius: 10px;
//padding: 35px 45px !important;
  padding: 15px 21px !important;
.fees-text {
  color: #646464;
  font-size: 20px;
}
.icon {
  margin: 0;
  font-size: 20px;
  color: #646464;
}
}
.fees-content-container {
.fees-content-item {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background-color: #fff;
  border-radius: 10px;
//padding: 35px 45px !important;
  padding: 15px 21px !important;
  color: #646464;
.fees-content-item-response {
  margin-inline-start: 5px !important;
}
}
}
}
.title-info {
  color: #B1B1B1 !important;
}
.title-info-gray {
  color: #9B9B9B !important;
  font-weight: bold !important;
}
</style>
